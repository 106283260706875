.offer-detail {
  .bn_offer-detail {
    .headlines {
      h2 {
        line-height: 34px;
        color: $brown;
      }
    }
    .dates {
      font-size: 2em;
      font-family: $std_font;
      font-weight: bold;
      color: $brown;
      margin-top: 20px;
    }
    .info {
      font-size: 2em;
      font-family: $std_font;
      font-weight: bold;
      color: $brown;
    }
    .img-item {
      margin-top: 20px;
    }
    .additionals {
      font-size: 1.6em;
      line-height: 26px;
      font-family: $std_font;
      color: $dark_grey;
      margin-top: 40px;
      b:first-child {
        color: $brown;
        font-family: $head_font;
        font-size: 30px;
        line-height: 2;
        margin-bottom: 10px;
      }
      ul {
        li {
          padding: 0;
          &:before {
            color: $dark_grey;
          }
        }
      }
    }
    .bottom_buttons {
      margin-top: 25px;
      .button_row {
        display: flex;
        justify-content: flex-start;
        > a {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $head_font;
          min-width: 180px;
          height: 50px;
          border: 2px solid $gold;
          text-decoration: none;
          font-size: 2em;
          padding: 15px;
        }
        .button_book {
          color: $white;
          background-color: $gold;
          margin-right: 20px;
          &:hover {
            color: $gold;
            background-color: $white;
          }
        }
        .button_request {
          color: $gold;
          background-color: $white;
          &:hover {
            color: $white;
            background-color: $gold;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .offer-detail {
    .bn_offer-detail {
      .bottom_buttons {
        .button_row {
          flex-direction: column;
          align-items: flex-start;
          .button_book {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}