.newsletter_container {
  padding: 50px 150px;
  margin-top: 20px;
  background-color: $brown;
  .newsletter_title {
    color: $white;
    font-family: $head_font;
    font-size: 3em;
    .wrap {
      pointer-events: none;
      .icon.toggle {
        display: none;
      }
    }
  }
}

/*CUSTOM-COLOR*/
#dynform_newsletter .bn__custom-color__background_button {
  background-color: transparent; /*color1*/
  color: $white;
  border: 1px solid $white;
  border-radius: 0!important;
  font-family: $head_font!important;
  &:hover {
    background-color: $white;
    color: $dark_grey;
  }
}
#dynform_newsletter {
  padding-top: 20px;
}
#dynform_newsletter .bn__form-grid {
  color: $white; /*color2*/
  max-width: none;
  margin: auto;
  * {
    font-family: $std_font;
  }
}
#dynform_newsletter input,
#dynform_newsletter select {
  border-bottom: 1px solid $white; /*color2*/
  &::placeholder {
    color: $white;
  }
}
#dynform_newsletter .bn__custom-color__background {
  background-color: $light_brown; /*color3*/
}
#dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row {
  width: 50%;
  display: inline-block;
}
#dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row .bn__form-grid__column {
  width: 50%;
}
#dynform_newsletter .bn__form-grid .bn__form-grid__column .bn__form-grid__column__item {
  width: 80%;
}
#dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row {
  .nameprefix label.bn__form-grid__column__item,
  .firstname label.bn__form-grid__column__item,
  .lastname label.bn__form-grid__column__item,
  .email label.bn__form-grid__column__item {
    display: none;
  }
}
#dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row.bn__form-grid__row--horizontal-sm-half {
  width: 75%;
  &:last-child {
    width: 25%;
    .bn__form-grid__column--xs-12 {
      width: 100%;
    }
  }
  .bn__form-grid__column--xs-12 {
    width: 33%;
    .bn__form-grid__column__item {
      width: 70%;
    }
  }
}
#dynform_newsletter input.bn__form-grid__invalid, #dynform_newsletter select.bn__form-grid__invalid {
  &::placeholder {
    color: #B92D00!important;
  }
}
#dynform_newsletter .bn__form-grid__column.bn__form-grid__invalid {
  background-color: $black_transparent_40;
}

@media only screen and (max-width: 1300px) {
  .newsletter_container {
    padding: 50px 100px;
  }
}

@media only screen and (max-width: 1099px) {
  .newsletter_container {
    padding: 25px;
    .newsletter_title {
      font-size: 2em;
    }
  }
}

@media only screen and (max-width: 850px) {
  .newsletter_container {
    padding: 25px;
    .newsletter_title {
      font-size: 2em;
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row {
      width: 100%;
      display: block;
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row .bn__form-grid__column {
      width: 50%;
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__column .bn__form-grid__column__item {
      width: 80%;
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row.bn__form-grid__row--horizontal-sm-half {
      &:last-child {
        width: 50%;
        margin-left: auto;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .newsletter_container {
    padding: 15px;
    .newsletter_title {
      font-size: 2em;
      width: 100%;
      height: 30px;
      position: relative;
      .wrap {
        pointer-events: auto;
        position: static;
        transform: none;
        display: flex;
        justify-content: center;
        transition: all .5s;
        cursor: pointer;
        span {
          white-space: nowrap;
          padding-right: 10px;
        }
        .icon.toggle {
          display: inline-block;
          font-size: 30px;
          transition: all .5s;
          cursor: pointer;
        }
      }
    }
    &.close {
      .newsletter_title {
        .wrap {
          .icon.toggle {
            transform: rotate(180deg);
          }
        }
      }
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row {
      width: 100%;
      display: block;
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row .bn__form-grid__column {
      width: 100%;
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__column .bn__form-grid__column__item {
      width: 100%;
    }
    #dynform_newsletter .bn__form-grid .bn__form-grid__block .bn__form-grid__row.bn__form-grid__row--horizontal-sm-half {
      &:last-child {
        width: 100%;
        margin: 0;
        .bn__form-grid__column__item {
          width: 100%;
        }
      }
    }
    #dynform_newsletter .consent_text {
      min-width: 250px;
    }
  }
}