.partnerlogos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;
  .partner {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    a {
      width: 100%;
    }
    img {
      width: auto;
      max-height: 90px;
      max-width: 140px;
      display: block;
      margin: auto;
    }
  }
}
@media only screen and (max-width: 1099px) {
  .partnerlogos {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .partnerlogos {
    .partner {
      img {
        max-height: 70px;
        max-width: 100px;
      }
    }
  }
}