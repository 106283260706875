.weather_detail {
  .item {
    margin: 50px 0;
    .weather_image {
      width: 50%;
      height: auto;
      margin: 20px auto;
    }
    .responsive-table {
      margin: 20px 0;
      .contenttable {
        th {
          color: $dark_grey;
          img {
            width: 40%;
          }
        }
        tr {
          color: $dark_grey;
          img {
            width: 40%;
          }
        }
        tr:nth-child(2n-1) {
          background-color: $light_brown;
        }
        tr:nth-child(2n) {
          background-color: $light_grey;
        }
      }
    }
  }
}

@media only screen and(max-width: 767px) {
  .weather_detail {
    .item {
      margin: 30px 0;
      .weather_image {
        width: 80%;
      }
    }
  }
}
