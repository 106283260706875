.headerboxes {
  position: absolute;
  top: 170px;
  right: 0;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  .headerbox {
    width: 200px;
    height: 160px;
    margin-bottom: 10px;
    background-color: $light_brown;
    padding: 25px;
    &.has_link {
      padding: 0;
      a {
        padding: 25px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
      }
    }
    .box_icon {
      text-align: center;
      .icon {
        font-size: 6em;
        font-family: $head_font;
        &:before {
          color: $white;
        }
        &.icon_new-smoking {
          font-size: 3em;
        }
      }
    }
    .box_description {
      font-family: $head_font;
      text-align: center;
      color: $white;
      font-size: 2em;
      z-index: 1;
      width: 100%;
    }
    &.non_static {
      &.text_centered {
        display: flex;
        align-items: center;
      }
    }
  }
  .headerbox.box_static {
    position: absolute;
    top: 340px;
    right: 0;
    padding: 0;
    background-color: $gold;
    margin-bottom: 0;
    z-index: 1;
    box-shadow: 0 3px 6px 0 $black_transparent_15;
    &.box_fixed {
      position: fixed;
      top: 170px
    }
    .box_link {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 25px;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .headerboxes {
    position: relative;
    bottom: 0;
    top: unset;
    .headerbox.box_static {
      &.box_single {
        position: relative;
        width: 100%;
        height: 45px;
        top: unset;
        bottom: 0;
        .box_link {
          padding: 0;
          .box_description {
            line-height: 18px;
          }
        }
      }
      &.docked {
        top: 115px;
        transition: top .5s;
        position: fixed;
      }
      &.undocked {
        top: unset;
        transition: top .5s;
      }
    }
  }
}

@media only screen and (min-width: 768px) and(max-width: 1099px) {
  .headerboxes {
    .headerbox {
      width: 160px;
      height: 115px;
      padding: 15px 20px;
      &.has_link {
        padding: 0;
        a {
          padding: 15px 20px;
        }
      }
      .box_icon {
        text-align: center;
        .icon {
          font-size: 3.7em;
          &.icon_new-smoking {
            font-size: 1.9em;
          }
        }
      }
      .box_description {
        font-size: 1.6em;
      }
    }
    .headerbox.box_static {
      &.box_single {
        width: 100%;
        height: 40px;
        .box_link {
          padding: 0;
        }
      }
      &.docked {
        top: 80px;
        transition: top .5s;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .headerboxes {
    .headerbox {
      &.mobile_visible {
        display: block;
        padding: 5px;
        width: auto;
        height: auto;
        &.has_link {
          padding: 0;
          a {
            padding: 5px;
          }
        }
        .box_icon {
          .icon {
            font-size: 40px;
          }
        }
      }
      &.box_static {
        width: auto;
        height: auto;
        .box_link {
          padding: 10px;
        }
        &.box_fixed {
          width: 100%;
        }
        &.docked {
          top: 65px;
        }
      }
      .box_description {
        font-size: 1.8em;
      }
    }
  }
}
