.google_maps {
  height: 600px;
  max-width: 1190px;
  width: calc(100% - 60px);
}

@media only screen and (max-width: 1099px) {
  .google_maps {
    height: 400px;
    width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 767px) {
  .google_maps {
    height: 300px;
    width: calc(100% - 30px);
  }
}