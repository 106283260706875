/*  ==========================================================================
    BUTTONS
    ========================================================================== */

@mixin Button() {
  width: 155px;
  height: 55px;
  background-color: $red;
  border: 1px solid $red;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $white;
    font-size: 2em;
    font-family: $head_font;
  }
  &:hover {
    background-color: $white;
    a {
      color: $dark_grey;
    }
  }
}