/*CUSTOM-COLOR*/
#dynform_request .bn__custom-color__background {
  background-color: $brown; /*color1*/
}
#dynform_request .bn__custom-color__font {
  color: $brown; /*color1*/
}
#dynform_request input:focus,
#dynform_request select:focus {
  border-bottom: 1px solid $brown; /*color1*/
}
#dynform_request textarea:focus {
  border: 1px solid $brown; /*color1*/
}
#dynform_request .bn__form-grid__button {
  background-color: #DDA741; /*color2*/
  color: $white;
  border-radius: 0!important;
  &:hover {
    background-color: $light_brown;
  }
}
#dynform_request .bn__custom-title .bn__custom-title__underline {
  margin-left: 0;
  box-sizing: inherit;
}
@media only screen and (max-width: 767px) {
  #dynform_request .bn__form-grid .bn__form-grid__block {
    padding: 10px;
  }
}