// Std-Content
.std_element {
  margin: 0 auto;

  .headlines {
    h2,
    .headline {
      font-size: 5em;
      font-family: $head_font;
      font-weight: $regular;
      line-height: 60px;
      color: $gold;
      text-align: left;
    }
    h3,
    .sub_headline {
      position: relative;
      font-size: 3em;
      font-family: $head_font;
      font-weight: $regular;
      line-height: 26px;
      color: $brown;
      margin-top: 5px;
      text-align: left;
      &:after {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        width: 85px;
        border-bottom: 2px solid $brown;
      }
    }

    &.firstElement {
      h1,
      .headline {
        font-size: 6em;
        font-family: $head_font;
        font-weight: $regular;
        line-height: 73px;
        color: $gold;
        text-align: left;
      }
      h2,
      .sub_headline {
        font-size: 3em;
        font-family: $head_font;
        font-weight: $regular;
        line-height: 26px;
        color: $brown;
        margin-top: 15px;
        text-align: left;
        border: none;
      }
    }
  }

  .std_text {
    margin-top: 45px;
    font-size: 1.6em;
    font-family: $std_font;
    line-height: 30px;
    font-weight: $regular;
    text-align: left;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.firstElement {
      text-align: left;
      margin-top: 40px;
    }
  }
  .shortlink {
    display: flex;
    margin-top: 20px;
    a {
      font-size: 2.5em;
      margin: auto;
    }
  }

  &.cutted {
    position: relative;
    margin-bottom: 70px;
    .std_text {
      height: 250px!important;
      overflow: hidden;
      position: relative;
      &:before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 50%,$white);
      }
    }
    .cuttext {
      position: absolute;
      bottom: 15px;
      left: 15px;
      transform: translateY(100%);
      font-size: 2em;
      font-family: utopia-std-display,serif;
      color: $gold;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 1099px) {
  .std_element {
    .headlines {
      h2,
      .headline {
        font-size: 3em;
        line-height: 40px;
      }
      h3,
      .sub_headline {
        font-size: 2em;
        line-height: 26px;
      }

      &.firstElement {
        h1,
        .headline {
          font-size: 4em;
          line-height: 50px;
        }
        h2,
        .sub_headline {
          font-size: 2em;
          line-height: 26px;
          margin-top: 5px;
        }
      }
    }

    .std_text {
      margin-top: 5px;
      font-size: 1.4em;
      line-height: 26px;

      &.firstElement {
        margin-top: 10px;
      }
    }
  }
}