@mixin price_table() {
  margin-top: 25px;
  .season_titles {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-right: 130px;
    .price_trigger {
      position: relative;
      font-family: $head_font;
      font-size: 2em;
      color: $brown;
      background-color: $light_grey;
      padding: 5px 55px;
      cursor: pointer;
      margin-right: 10px;
      margin-top: 20px;
      &.active {
        color: $white;
        background-color: $brown;
        &:after {
          content: " ";
          border-bottom: 13px solid #807868;
          border-right: 13px solid #807868;
          position: absolute;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%)rotate(45deg);
        }
      }
    }
    .child_price_link {
      position: absolute;
      right: 0;
      bottom: 0;
      font-family: $head_font;
      font-size: 2em;
      text-decoration: underline;
      color: $brown;
      &:hover {
        color: $gold;
      }
    }
  }
  .season_table {
    display: none;
    &.active {
      display: block;
    }
    .price_table {
      margin: 20px 0;
      overflow: visible;
      table {
        min-width: 400px;
        tr {
          th {
            width: 24%;
            background-color: $light_grey;
            color: $dark_grey;
            font-family: $head_font;
            font-size: 2em;
            border-bottom: 2px solid $brown;
            border-top: 2px solid $brown;
            border-right: 1px solid $brown;
            &:first-child {
              width: 28%;
              border-left: 1px solid $brown;
            }
            .hp,
            .hp_plus {
              position: relative;
              text-decoration: underline;
              &:hover {
                color: $gold;
                .popup {
                  display: block;
                }
              }
              .popup {
                position: absolute;
                display: none;
                left: 50%;
                bottom: calc(100% + 15px);
                padding: 15px;
                transform: translateX(-50%);
                border: 2px solid $brown;
                background-color: $light_grey;
                color: $dark_grey;
                width: 100%;
                font-size: 13px;
                text-align: center;
                &:after {
                  content: " ";
                  position: absolute;
                  bottom: -12px;
                  left: 50%;
                  transform: translateX(-50%)rotate(45deg);
                  width: 20px;
                  height: 20px;
                  background-color: $light_grey;
                  border-right: 2px solid $brown;
                  border-bottom: 2px solid $brown;
                }
              }
            }
          }
          td {
            background-color: #fff;
            color: $brown;
            width: 24%;
            font-family: $head_font;
            font-weight: bold;
            font-size: 2em;
            border-bottom: 2px solid $brown;
            border-right: 1px solid $brown;
            &:first-child {
              width: 28%;
              border-left: 1px solid $brown;
              font-weight: $regular;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .season_table {
      .price_table {
        table {
          tr {
            th,td {
              font-size: 1.6em;
            }
          }
        }
      }
    }
  }
  @media only screen and(max-width: 550px) {
    .season_table {
      .price_table {
        overflow-x: auto;
        table {
          tr {
            th {
              font-size: 1.4em;
              .hp,
              .hp_plus {
                position: relative;
                text-decoration: none;
                &:hover {
                  color: $dark_grey;
                  .popup {
                    display: none;
                  }
                }
              }
            }
            td {
              font-size: 1.4em;
            }
          }
        }
      }
    }
    .season_titles {
      padding-right: 0;
      padding-bottom: 45px;
      .price_trigger {
        width: 100%;
        text-align: center;
        padding: 5px 10px;
        margin-right: 0;
      }
    }
  }
}