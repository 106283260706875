//.top_offers {
//  @include center_text();
//  .std_content_wrapper {
//    position: relative;
//    z-index: 1;
//    padding: 0 40px;
//    .std_text {
//      margin-bottom: 50px;
//    }
//  }
//  .bn_top_offers {
//    position: relative;
//    overflow: hidden;
//    padding-bottom: 80px;
//    margin-top: 50px;
//    .slider {
//      .slick-list {
//        overflow: visible;
//        .item {
//          padding: 0 10px;
//          .item_wrapper {
//            opacity: .55;
//            transition: all 1s;
//            display: flex;
//            &.setOpacity {
//              opacity: 1;
//            }
//            &.reverse {
//              flex-direction: row-reverse;
//            }
//            .left {
//              width: 60%;
//            }
//            .right {
//              position: relative;
//              width: 40%;
//              background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
//              .bottom_content {
//                position: relative;
//                width: 100%;
//                height: 100%;
//                .content_wrapper {
//                  height: 100%;
//                  display: flex;
//                  flex-direction: column;
//                  justify-content: flex-end;
//                  padding: 0 30px 130px;
//                  > div {
//                    color: $white;
//                    &.date {
//                      font-size: 2.3em;
//                      line-height: 34px;
//                      font-family: $head_font;
//                      position: absolute;
//                      right: 30px;
//                      top: 30px;
//                    }
//                    &.title {
//                      font-family: $head_font;
//                      font-weight: 700;
//                      line-height: 42px;
//                      font-size: 4em;
//                    }
//                    &.description {
//                      font-family: $std_font;
//                      font-size: 2em;
//                      line-height: 26px;
//                    }
//                  }
//                }
//                .button_row {
//                  position: absolute;
//                  left: 30px;
//                  bottom: 30px;
//                  .more_button {
//                    font-size: 2em;
//                    font-family: $head_font;
//                    height: 50px;
//                    min-width: 120px;
//                    padding: 0 15px;
//                    display: -ms-flexbox;
//                    display: -webkit-flex;
//                    display: flex;
//                    align-items: center;
//                    justify-content: center;
//                    background-color: $gold;
//                    color: $white;
//                    text-decoration: none;
//                    &:hover {
//                      background-color: $light_brown;
//                    }
//                  }
//                }
//              }
//              .price_nights {
//                position: absolute;
//                top: 20px;
//                left: 0;
//                transform: translateX(-50%);
//                width: 200px;
//                height: 200px;
//                border-radius: 100%;
//                background-color: $gold;
//                .price_nights_wrapper {
//                  position: absolute;
//                  top: 50%;
//                  left: 0;
//                  transform: translateY(-50%);
//                  text-align: center;
//                  width: 100%;
//                  > span {
//                    text-align: center;
//                    color: $white;
//                    line-height: 30px;
//                    font-size: 2.4em;
//                    display: block;
//                    font-family: $head_font;
//                    &.from {
//                      display: inline-block;
//                    }
//                    &.price {
//                      display: inline-block;
//                      font-size: 5.2em;
//                      line-height: 46px;
//                    }
//                  }
//                }
//              }
//            }
//          }
//        }
//      }
//      .icon.slick-arrow {
//        position: absolute;
//        bottom: -80px;
//        right: 125px;
//        font-size: 65px;
//        cursor: pointer;
//        background-color: $light_brown;
//        &.next {
//          right: 40px;
//        }
//        &:hover {
//          background-color: $brown;
//        }
//      }
//    }
//  }
//}
//
//@media only screen and (max-width: 1099px) {
//  .top_offers {
//    .std_content_wrapper {
//      padding: 0 40px;
//      .std_text {
//        margin-bottom: 0;
//      }
//    }
//    .bn_top_offers {
//      padding-top: 60px;
//      padding-bottom: 45px;
//      margin-top: 0;
//      .slider {
//        padding: 0;
//        .slick-list {
//          .item {
//            padding: 0;
//            .item_wrapper {
//              opacity: 1;
//              .left {
//                width: 50%;
//              }
//              .right {
//                width: 50%;
//                .bottom_content {
//                  .content_wrapper {
//                    padding: 0 20px 100px;
//                    > div {
//                      &.date {
//                        font-size: 1.6em;
//                        line-height: 26px;
//                        right: 10px;
//                        top: 10px;
//                      }
//                      &.title {
//                        line-height: 33px;
//                        font-size: 3em;
//                      }
//                      &.description {
//                        font-size: 1.6em;
//                        line-height: 26px;
//                      }
//                    }
//                  }
//                  .button_row {
//                    left: 20px;
//                    bottom: 20px;
//                    .more_button {
//                      font-size: 1.6em;
//                      height: 40px;
//                      min-width: 105px;
//                    }
//                  }
//                }
//                .price_nights {
//                  top: -32px;
//                  left: -22px;
//                  width: 115px;
//                  height: 115px;
//                  .price_nights_wrapper {
//                    > span {
//                      line-height: 20px;
//                      font-size: 1.4em;
//                      &.price {
//                        font-size: 3em;
//                        line-height: 30px;
//                      }
//                    }
//                  }
//                }
//              }
//            }
//          }
//        }
//        .icon.slick-arrow {
//          bottom: -45px;
//          right: 65px;
//          font-size: 40px;
//          &.next {
//            right: 15px;
//          }
//        }
//      }
//    }
//  }
//}
//
//@media only screen and (max-width: 900px) {
//  .top_offers {
//    .bn_top_offers {
//      .slider {
//        .slick-list {
//          .item {
//            .item_wrapper {
//              .right {
//                .bottom_content {
//                  .content_wrapper {
//                    > div {
//                      &.description {
//                        display: none;
//                      }
//                    }
//                  }
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//}
//
//@media only screen and (max-width: 767px) {
//  .top_offers {
//    .bn_top_offers {
//      padding-top: 30px;
//      .slider {
//        .slick-list {
//          .item {
//            .item_wrapper {
//              display: block;
//              .left {
//                width: 100%;
//              }
//              .right {
//                width: 100%;
//                .bottom_content {
//                  padding: 50px 15px 20px;
//                  .content_wrapper {
//                    padding: 0 20px 60px;
//                    > div {
//                      &.date {
//                        position: inherit;
//                        margin-bottom: 15px;
//                      }
//                      &.title {
//                        font-size: 2.5em;
//                        line-height: 30px;
//                      }
//                      &.description {
//                        display: block;
//                        margin-top: 10px;
//                      }
//                    }
//                  }
//                  .button_row {
//                    left: 35px;
//                  }
//                }
//                .price_nights {
//                  left: auto;
//                  right: -45px;
//                  top: -80px;
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//}
//

.top_offers {
  @include center_text();
  .std_content_wrapper {
    position: relative;
    z-index: 1;
    padding: 0 40px;
    .std_text {
      margin-bottom: 50px;
    }
  }
  .bn_top_offers {
    padding-bottom: 80px;
    margin-top: 50px;
    .offer_boxes {
      display: flex;
      justify-content: center;
      .slick-track {
        display: flex;
        .slick-slide {
          display: flex;
          height: auto;
          .offer_box {
            height: 100%;
          }
        }
      }
      .icon.slick-arrow {
        position: absolute;
        bottom: -80px;
        right: 125px;
        font-size: 65px;
        cursor: pointer;
        background-color: $light_brown;
        &.next {
          right: 40px;
        }
        &:hover {
          background-color: $brown;
        }
      }
      .offer_box {
        position: relative;
        padding: 0 10px;
        display: block;
        max-width: 50%;
        .item_wrap {
          background-color: $brown;
          height: 100%;
          img {
            display: block;
          }
          &:nth-child(2n) {
            background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
          }
          .box_info {
            position: relative;
            padding: 30px 25px 30px 30px;
            .date {
              font-family: $head_font;
              font-size: 1.6em;
              color: $white;
              display: block;
            }
            .title {
              font-family: $head_font;
              font-weight: bold;
              font-size: 3em;
              color: $white;
              margin-top: 15px;
              display: block;
            }
            .subtitle {
              font-family: $std_font;
              font-size: 2em;
              color: $white;
              margin-top: 20px;
              display: block;
            }
            .offer_prices {
              margin-top: 20px;
              .prices {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid $white;
                padding: 5px;
                .wrap {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  span {
                    color: $white;
                    font-size: 1.8em;
                    &.price {
                      font-size: 2.4em;
                      font-family: $head_font;
                    }
                  }
                  .inner_offer_link {
                    font-size: 1.8em;
                  }
                }
                &:last-child {
                  border-bottom: 1px solid $white;
                }
                .inner_offer_link.mobile {
                  display: none;
                }
              }
            }
          }
        }
        .offer_link {
          display: none;
          position: absolute;
          right: 25px;
          bottom: 20px;
          min-width: 145px;
          height: 50px;
          border: 2px solid $white;
          color: $white;
          padding: 10px 20px;
          font-family: $head_font;
          font-size: 2em;
          text-decoration: none;
          text-align: center;
          &:hover {
            background-color: $white;
            color: $dark_grey;
          }
        }
      }
      .slick-list {
        .offer_box {
          max-width: none;
        }
      }
    }
  }
}

@media only screen and(max-width: 1400px) {
  .top_offers {
    .bn_top_offers {
      .offer_boxes {
        .offer_box {
          .item_wrap {
            .box_info {
              padding: 30px 20px;
              .offer_prices {
                .prices {
                  .wrap {
                    span {
                      font-size: 1.6em;
                      &.price {
                        font-size: 2.2em;
                      }
                    }
                    .inner_offer_link {
                      font-size: 1.6em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 1099px) {
  .top_offers {
    .bn_top_offers {
      .offer_boxes {
        .icon.slick-arrow {
          bottom: -45px;
          right: 75px;
          font-size: 40px;
          &.next {
            right: 29px;
          }
        }
        .offer_box {
          .item_wrap {
            .box_info {
              .title {
                font-size: 2.5em;
              }
              .subtitle {
                font-size: 1.8em;
              }
            }
          }
        }
        .offer_link {
          font-size: 1.8em;
        }
      }
    }
  }
}

@media only screen and(max-width: 850px) {
  .top_offers {
    .bn_top_offers {
      .offer_boxes {
        max-width: 600px;
        .offer_box {
          max-width: 450px;
        }
      }
    }
  }
}

@media only screen and(max-width: 767px) {
  .top_offers {
    .bn_top_offers {
      margin: 50px 0 30px;
      .offer_boxes {
        .icon.slick-arrow {
          right: 70px;
          &.next {
            right: 25px;
          }
        }
        padding: 0 15px;
        .offer_box {
          .item_wrap {
            width: 100%;
            .box_info {
              padding: 15px 10px 25px 10px;
              .title {
                font-size: 2.5em;
              }
              .subtitle {
                font-size: 1.8em;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and(max-width: 500px) {
  .top_offers {
    .bn_top_offers {
      .offer_boxes {
        .offer_box {
          .item_wrap {
            .box_info {
              .offer_prices {
                .prices {
                  .wrap {
                    display: block;
                    .inner_offer_link {
                      display: none;
                    }
                  }
                  .inner_offer_link.mobile {
                    display: block;
                    font-size: 1.6em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and(max-width: 400px) {
  .top_offers {
    .bn_top_offers {
      .offer_boxes {
        .offer_box {
          padding: 0;
          .item_wrap {
            width: 100%;
            .icon.slick-arrow {
              right: 65px;
              &.next {
                right: 20px;
              }
            }
            .box_info {
              .offer_prices {
                .prices {
                  .wrap {
                    display: block;
                    span {
                      font-size: 1.4em;
                      &.price {
                        font-size: 2em;
                      }
                    }
                  }
                  .inner_offer_link.mobile {
                    font-size: 1.4em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}