/*  ==========================================================================
    COLORS
    List all colors concerning your project here
    ========================================================================== */

$white: #FFF;
$white_transparent_55: rgba(255, 255, 255, 0.55);
$black: #000;
$black_transparent_40: rgba(0, 0, 0, 0.40);
$black_transparent_15: rgba(0, 0, 0, 0.15);

$dark_grey: #36332C;
$light_grey: #F0EEE9;

$brown: #807868;
$brown_transparent_90: rgba(128, 120, 104, 0.9);
$brown_transparent_70: rgba(128, 120, 104, 0.7);
$brown_transparent_20: rgba(128, 120, 104, 0.2);
$light_brown: #BEB199;

$gold: #C09E44;

$red: #AE2F3D;