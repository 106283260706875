/*  ==========================================================================
    STD GALLERY
    ========================================================================== */

.imagegallery {
  padding-top: 25px;
  padding-bottom: 80px;
  position: relative;
  &.std_element {
    margin-top: 50px;
  }
  @include center_text();
  .std_content_wrapper {
    position: relative;
    z-index: 1;
    .std_text {
      margin-bottom: 50px;
    }
  }
  .background_half {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    z-index: 0;
    background-color: $light_grey;
  }
  .slider {
    .item {
      .image {
        display: inline-block;
        margin-bottom: 6px;
        &:nth-child(1) {
          width: calc(55% - 8px);
        }
        &:nth-child(2) {
          width: calc(45% - 2px);
          margin-left: 10px;
        }
        &:nth-child(3) {
          width: calc(45% - 2px);
        }
        &:nth-child(4) {
          width: calc(55% - 8px);
          margin-left: 10px;
        }
      }
    }
    .icon.slick-arrow {
      position: absolute;
      bottom: -65px;
      left: 30px;
      font-size: 60px;
      cursor: pointer;
      background-color: $light_brown;
      &.next {
        left: 105px;
      }
      &:hover {
        background-color: $brown;
      }
    }
    &.mobile {
      display: none;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

@media only screen and (max-width: 1099px) {
  .imagegallery {
    padding-top: 25px;
    .slider {
      .item {
        .image {
          margin-bottom: 1px;
          &:nth-child(2) {
            margin-left: 5px;
          }
          &:nth-child(4) {
            margin-left: 5px;
          }
        }
      }
      .icon.slick-arrow {
        left: 20px;
        bottom: -40px;
        font-size: 40px;
        &.next {
          left: 70px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .imagegallery {
    padding-top: 25px;
    .slider {
      .icon.slick-arrow {
        left: 15px;
        &.next {
          left: 65px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .imagegallery {
    padding-top: 25px;
    .slider {
      display: none;
      .item {
        .image {
          &:first-child {
            width: auto;
          }
        }
      }
      &.mobile {
        display: block;
        margin-top: 20px;
        .icon.slick-arrow {
          bottom: -45px;
          left: auto;
          right: 65px;
          &.next {
            right: 15px;
            left: auto;
          }
          &:hover {
            background-color: $brown;
          }
        }
      }
    }
  }
}