.download_boxes {
  .download_wrapper {
    border-top: 2px solid $brown;
    border-bottom: 2px solid $brown;
    margin: 20px auto;
    padding: 5px;
    transition: padding .4s;
    .trigger {
      position: relative;
      cursor: pointer;
      .title {
        font-size: 2.5em;
        font-family: $head_font;
        color: $brown;
        padding-right: 30px;
      }
      .icon {
        position: absolute;
        top: 0;
        right: 0;
        &:before {
          color: $brown;
        }
        &.close_box {
          font-size: 20px;
        }
        &.open_box {
          font-size: 40px;
        }
      }
    }

    .box_content {
      .download_box {
        margin: 20px 0;
        width: 100%;
        display: block;
        .download_link {
          display: flex;
          height: 100%;
          text-decoration: none;
          .download_img {

          }
          .download_info {
            width: 100%;
            margin: 5px 0 5px 20px;
            .download_title {
              font-size: 2.5em;
              font-family: $head_font;
              font-weight: 700;
              color: $gold;
            }
            .download_description {
              font-size: 1.6em;
              font-family: $std_font;
              color: $brown;
              margin-top: 10px;
            }
          }
        }
      }
    }
    &.close {
      .trigger {
        .icon.open_box {
          display: inline-block;
          right: -9px;
        }
        .icon.close_box {
          display: none;
        }
      }
    }

    &.open {
      padding: 10px 5px;
      .trigger {
        .icon.close_box {
          display: inline-block;
          top: 7px;
        }
        .icon.open_box {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .download_boxes {
    .download_wrapper {
      .trigger {
        .title {
          font-size: 2.3em;
        }
      }

      .box_content {
        .download_box {
          margin-top: 40px;
          &:first-child {
            margin-top: 20px;
          }
          .download_link {
            display: block;
            .download_img {
              margin: auto;
              width: 100%;
            }
            .download_info {
              width: 100%;
              margin: 10px;
              .download_title {
                font-size: 2em;
              }
              .download_description {
                font-size: 1.4em;
              }
            }
          }
        }
      }
    }
  }
}