.offer_list {
  .bn_offer_list {
    margin: 100px 0 30px;
    .categories_wrapper {
      .categories_title {
        font-size: 3em;
        font-family: $head_font;
        color: $brown;
      }
      .categories {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .category_item {
          font-family: $head_font;
          font-size: 2em;
          color: $brown;
          width: 33%;
          margin-top: 10px;
          padding-left: 25px;
          .cat_checkbox {
            display: none;
          }
          .item.icon {
            font-size: 18px;
          }
          .cat_checkbox:not(:checked) + .cat_name:after {
            display: none;
          }
          .cat_name {
            position: relative;
            cursor: pointer;
            padding-left: 2px;
            &:before {
              content: '';
              position: absolute;
              left: -25px;
              top: 0;
              width: 20px;
              height: 20px;
              border: 2px solid $brown;
              background: #fff;
              border-radius: 6px;
            }
            &:after {
              content: " ";
              position: absolute;
              top: 4px;
              left: -21px;
              color: $brown;
              border: 6px solid $brown;
              border-radius: 3px;
            }
          }
        }
      }
      .reset_cat {
        position: relative;
        display: block;
        width: 33%;
        margin-left: 20px;
        font-family: $head_font;
        font-size: 1.6em;
        color: $brown;
        cursor: pointer;
        margin-top: 10px;
        .icon {
          position: absolute;
          top: 50%;
          left: -15px;
          transform: translateY(-50%);
          font-size: 7px;
          font-weight: bold;
          &:before {
            color: $brown;
          }
        }
        &:hover{
          color: $gold;
          .icon {
            &:before {
              color: $gold;
            }
          }
        }
      }
    }
    .offer_boxes {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 50px;
      padding-left: 50px;
      padding-right: 50px;
      .offer_box {
        display: none;
      }
      .offer_box.selected {
        position: relative;
        width: calc(100% / 3 - 40px);
        margin: 0 20px 40px 20px;
        display: block;
        background-color: $brown;
        img {
          display: block;
        }
        &:nth-child(2n) {
          background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
        }
        //&:nth-child(3n) {
        //  margin-right: 0;
        //}
        .cat_icons {
          position: absolute;
          top: 10px;
          left: 10px;
          display: flex;
          justify-content: flex-start;
          .item {
            background-color: $light_brown;
            font-size: 20px;
            margin-right: 10px;
            &.icon {
              position: relative;
              width: 40px;
              height: 40px;
              &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%)translateY(-50%);
                font-size: 25px;
                color: $white;
              }
            }
          }
        }
        .box_info {
          position: relative;
          padding: 30px 25px 30px 30px;
          .date {
            font-family: $head_font;
            font-size: 1.6em;
            color: $white;
            display: block;
          }
          .title {
            font-family: $head_font;
            font-weight: bold;
            font-size: 3em;
            color: $white;
            margin-top: 15px;
            display: block;
          }
          .subtitle {
            font-family: $std_font;
            font-size: 2em;
            color: $white;
            margin-top: 20px;
            display: block;
          }
          .price_nights_mobile {
            display: none;
          }
          .price_nights {
            position: absolute;
            top: -75px;
            right: -45px;
            width: 140px;
            height: 140px;
            border-radius: 100%;
            background-color: $gold;
            .price_nights_wrapper {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              text-align: center;
              width: 100%;
              > span {
                text-align: center;
                color: $white;
                line-height: 20px;
                font-size: 1.6em;
                display: block;
                font-family: $head_font;
                &.from {
                  display: inline-block;
                }
                &.price {
                  display: inline-block;
                  font-size: 4em;
                  line-height: 46px;
                }
              }
            }
          }
          .offer_prices {
            margin-top: 20px;
            .prices {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-top: 1px solid $white;
              padding: 5px;
              .wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                width: 100%;
                span {
                  color: $white;
                  font-size: 1.8em;
                  &.price {
                    font-size: 2.4em;
                    font-family: $head_font;
                  }
                }
                .inner_offer_link {
                  font-size: 1.8em;
                }
              }
              &:last-child {
                border-bottom: 1px solid $white;
              }
            }
          }
        }
        .offer_link {
          display: none;
          position: absolute;
          right: 25px;
          bottom: 20px;
          min-width: 145px;
          height: 50px;
          border: 2px solid $white;
          color: $white;
          padding: 10px 20px;
          font-family: $head_font;
          font-size: 2em;
          text-decoration: none;
          text-align: center;
          &:hover {
            background-color: $white;
            color: $dark_grey;
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 1400px) {
  .offer_list {
    .bn_offer_list {
      .offer_boxes {
        .offer_box.selected {
          .box_info {
            padding: 30px 20px;
            .offer_prices {
              .prices {
                .wrap {
                  span {
                    font-size: 1.6em;
                    &.price {
                      font-size: 2.2em;
                    }
                  }
                  .inner_offer_link {
                    font-size: 1.6em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and(max-width: 1350px) {
  .offer_list {
    .bn_offer_list {
      .offer_boxes {
        padding-left: 20px;
        padding-right: 20px;
        .offer_box.selected {
          width: calc(100% / 3 - 20px);
          margin: 0 10px 40px;
        }
      }
    }
  }
}
@media only screen and(max-width: 1300px) {
  .offer_list {
    .bn_offer_list {
      .offer_boxes {
        .offer_box.selected {
          //width: calc(33% - 38px);
          margin-bottom: 40px;
          .box_info {
            .price_nights {
              right: -35px;
              width: 120px;
              height: 120px;
              .price_nights_wrapper {
                > span {
                  &.price {
                    line-height: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 1099px) {
  .offer_list {
    .bn_offer_list {
      margin: 70px 0 30px;
      .offer_boxes {
        padding: 0 20px;
        .offer_box.selected {
          width: calc(50% - 20px);
          background-color: $brown;
          &:nth-child(4n+1),
          &:nth-child(4n+4) {
            background-image: none;
            background-color: $brown;
          }

          &:nth-child(4n+2),
          &:nth-child(4n+3) {
            background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
          }
          .box_info {
            .title {
              font-size: 2.5em;
            }
            .subtitle {
              font-size: 1.8em;
            }
            .price_nights {
              right: -25px;
              .price_nights_wrapper {
                > span {
                  font-size: 1.4em;
                  &.price {
                    font-size: 3em;
                    line-height: 35px;
                  }
                }
              }
            }
          }
        }
        .offer_link {
          font-size: 1.8em;
        }
      }
    }
  }
}

@media only screen and(max-width: 899px) {
  .offer_list {
    .bn_offer_list {
      .offer_boxes {
        .offer_box.selected {
          .box_info {
            .offer_prices {
              .prices {
                .wrap {
                  display: block;
                  .inner_offer_link {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 767px) {
  .offer_list {
    .bn_offer_list {
      margin: 50px 0 30px;
      .categories_wrapper {
        .categories_title {
          font-size: 2.5em;
        }
        .categories {
          .category_item {
            width: 50%;
          }
        }
        .reset_cat {
          width: 50%;
        }
      }
      .offer_boxes {
        padding: 0 15px;
        .offer_box.selected {
          width: 100%;
          &:nth-child(2n - 1) {
            background-image: none;
            background-color: $brown;
          }
          &:nth-child(2n) {
            background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
          }
          .box_info {
            padding: 15px 10px 25px 10px;
            .title {
              font-size: 2.5em;
            }
            .subtitle {
              font-size: 1.8em;
              margin-top: 10px;
            }
            .price_nights {
              right: -25px;
              .price_nights_wrapper {
                > span {
                  font-size: 1.4em;
                  &.price {
                    font-size: 3em;
                    line-height: 35px;
                  }
                }
              }
            }
          }
          .offer_link {
            font-size: 1.8em;
            min-width: 120px;
            height: 40px;
            right: 20px;
            bottom: 15px;
            padding: 5px 15px;
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 500px) {
  .offer_list {
    .bn_offer_list {
      .categories_wrapper {
        .categories {
          flex-direction: column;
          .category_item {
            margin-left: 20px;
            width: 100%;
          }
        }
        .reset_cat {
          margin-left: 40px;
        }
      }
    }
  }
}

@media only screen and(max-width: 400px) {
  .offer_list {
    .bn_offer_list {
      .offer_boxes {
        .offer_box.selected {
          width: 100%;
          .box_info {
            .price_nights_mobile {
              display: block;
              margin-top: 20px;
              .price_nights_wrapper {
                > span {
                  font-size: 1.6em;
                  color: $white;
                  font-family: $std_font;
                  &.price {
                    font-size: 2em;
                  }
                }
              }
            }
            .price_nights {
              display: none;
            }
            .offer_prices {
              .prices {
                .wrap {
                  display: block;
                  span {
                    font-size: 1.4em;
                    &.price {
                      font-size: 2em;
                    }
                  }
                }
                .inner_offer_link {
                  font-size: 1.4em;
                }
              }
            }
          }
          .offer_link {
            font-size: 1.8em;
            min-width: 120px;
            height: 40px;
            right: 20px;
            bottom: 15px;
            padding: 5px 15px;
          }
        }
      }
    }
  }
}