/*  ==========================================================================
    GENERAL
    Useful global helpers

    INFO:
    - use it with care, its not a trash can!
    - contributions for the kickstarter are welcome!
    ========================================================================== */

// clearfix
@mixin clearfix() {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// reset default button reset
@mixin resetHTMLButton() {
    appearance: none;
    background: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
}

// responsive image
@mixin responsiveImg() {
    display: block;
    height: auto;
    width: 100%;
}

// cover image
@mixin coverImg() {
    display: block;
    height: auto;
    left: 50%;
    max-height: none;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

// cover image reset
@mixin coverImgReset() {
    display: initial;
    left: auto;
    max-height: 100%;
    max-width: 100%;
    min-height: 0;
    min-width: 0;
    position: static;
    top: auto;
    transform: translate(0, 0);
}

// create table
@mixin createTable() {
    display: table;
    > * {
        display: table-row;
        height: 100%;
        width: 100%;
        > * {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

// reset table
@mixin resetTable() {
    display: initial;
    > * {
        display: initial;
        height: auto;
        width: auto;
        > * {
            display: initial;
            vertical-align: initial;
        }
    }
}

// transforms hex color to rgba
@mixin hex2rgba($color, $opacity: 0.7) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin passiveLink() {
    a {
        color: $dark_grey;
        text-decoration: none;
        &:visited {
            color: $dark_grey;
        }
        &:hover {
            color: $gold;
        }
    }
}