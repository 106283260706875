.room_list {
  .bn_room_list {
    margin-top: 50px;
    &.order_items {
      .room {
        &:first-child {
          margin-top: 100px;
        }
      }
    }
    .wrapper {
      display: flex;
      flex-direction: column;
    }
    .room {
      margin-top: 100px;
      order: 2;

      &:first-child {
        margin-top: 0;
      }
      &.filtered {
        order: 1;
        margin-top: 0;
      }
      .room_preview {
        position: relative;
        display: flex;
        .img-slider {
          position: relative;
          width: 61%;
          .slider_item {
            float: left;
          }
          .icon.slick-arrow {
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: 36px;
            z-index: 1;
            cursor: pointer;
            &.next {
              top: 50px;
            }
            &:before {
              color: $white;
              background-color: $brown_transparent_20;
            }
            &:hover {
              &:before {
                color: $gold;
              }
            }
          }
          .slick-dots {
            display: flex;
            position: absolute;
            left: 10px;
            bottom: 20px;
            justify-content: flex-start;
            cursor: pointer;
            li {
              display: inline-block;
              margin-right: 10px;
              background-color: rgba(255, 255, 255, 0.5);
              height: 3px;
              width: 40px;
              position: relative;
              padding: 0;
              &:before {
                content: "";
                margin-right: 0;
              }
              button {
                display: none;
              }
            }
            .slick-active:before {
              content: '';
              background-color: rgba(255, 255, 255, 1);
              position: absolute;
              height: 3px;
              width: 0;
              left: 0;
              -webkit-animation: linear infinite alternate;
              -webkit-animation-name: loading;
              -webkit-animation-duration: 6s;
            }
            @keyframes loading {
              0% {
                width: 0
              }

              100% {
                width: 40px
              }
            }
          }
        }
        .room_info {
          display: flex;
          align-items: flex-end;
          width: 39%;
          .room_box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 45%;
            height: 64%;
            background-color: $brown;
            padding: 65px 20px 0 100px;
            .room_name {
              color: $white;
              font-size: 4em;
              font-family: $head_font;
              font-weight: bold;
              display: block;
              margin-bottom: 10px;
            }
            .subtitle {
              display: block;
              color: $white;
              font-size: 1.8em;
            }
            .size_person {
              color: $white;
              font-size: 1.6em;
            }
            .sketch {
              font-size: 1.6em;
            }
            .price_nights_wrapper {
              display: none;
              > span {
                color: $white;
                font-size: 1.6em;
              }
            }
          }
          .price_nights {
            position: absolute;
            top: -10px;
            right: 50px;
            width: 200px;
            height: 200px;
            border-radius: 100%;
            background-color: $gold;
            .price_nights_wrapper {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              text-align: center;
              width: 100%;
              > span {
                text-align: center;
                color: $white;
                line-height: 30px;
                font-size: 2.4em;
                display: block;
                font-family: $head_font;
                &.from {
                  display: inline-block;
                }
                &.price {
                  display: inline-block;
                  font-size: 5.2em;
                  line-height: 46px;
                }
              }
            }
          }
          .price_nights {
            &.no_price {
              display: none;
            }
          }
          .room_box {
            .price_nights_wrapper {
              &.no_price {
                display: none;
              }
            }
          }
          .button_row {
            margin-left: 20px;
            .read_more > span {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: $head_font;
              min-width: 180px;
              height: 50px;
              border: 2px solid $gold;
              text-decoration: none;
              font-size: 2em;
              padding: 15px;
              cursor: pointer;
              color: $gold;
              background-color: $white;
              .icon {
                font-size: 33px;
                &:before {
                  color: $gold;
                }
              }
              &:hover {
                color: $white;
                background-color: $gold;
                .icon {
                  &:before {
                    color: $white;
                  }
                }
              }
              &.less {
                display: none;
                padding-right: 22px;
                .icon {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }
      .room_description {
        .description {
          font-size: 1.6em;
          font-family: $std_font;
          margin-top: 50px;
        }
        .price_list {
          @include price_table();
        }
        .bottom_buttons {
          margin-top: 25px;
          .button_row {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            > a {
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: $head_font;
              min-width: 180px;
              height: 50px;
              border: 2px solid $gold;
              text-decoration: none;
              font-size: 2em;
              padding: 15px;
              margin-top: 10px;
            }
            .button_book {
              color: $white;
              background-color: $gold;
              margin-right: 20px;
              &:hover {
                color: $gold;
                background-color: $white;
              }
            }
            .button_request {
              color: $gold;
              background-color: $white;
              &:hover {
                color: $white;
                background-color: $gold;
              }
            }
          }
          .button_row2 {
            display: flex;
            justify-content: space-between;
            margin-top: 65px;
            > a {
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: $head_font;
              width: calc(33% - 20px);
              height: 60px;
              text-decoration: none;
              font-size: 2em;
              padding: 15px;
              color: $brown;
              background-color: $light_grey;
              margin-right: 20px;
              &:last-child {
                margin-right: 0;
              }
              &:hover {
                color: $white;
                background-color: $brown;
              }
            }
          }
        }
      }
      &.opened {
        .room_preview {
          .room_info {
            .button_row {
              .read_more {
                span.more {
                  display: none;
                }
                span.less {
                  display: flex;
                }
              }
            }
          }
        }
        .room_description {

        }
      }
    }
  }
}

@media only screen and(max-width: 1099px) {
  .room_list {
    .bn_room_list {
      .room {
        .room_preview {
          .room_info {
            .room_box {
              padding: 40px 15px 0 75px;
              .room_name {
                font-size: 3em;
              }
              .subtitle {
                font-size: 1.6em;
              }
              .size_person {
                font-size: 1.4em;
              }
            }
            .price_nights {
              width: 115px;
              height: 115px;
              .price_nights_wrapper {
                > span {
                  line-height: 20px;
                  font-size: 1.4em;
                  &.price {
                    font-size: 3em;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
        .room_description {
          .description {
            font-size: 1.4em;
          }
          .bottom_buttons {
            .button_row {
              > a {
                font-size: 1.8em;
              }
            }
            .button_row2 {
              > a {
                width: calc(33% - 10px);
                height: 50px;
                font-size: 1.8em;
                padding: 10px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 900px) {
  .room_list {
    .bn_room_list {
      .room {
        .room_preview {
          .room_info {
            .room_box {
              padding: 40px 15px 0 40px;
              top: 45%;
            }
            .price_nights {
              top: -45px;
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 767px) {
  .room_list {
    .bn_room_list {
      &.order_items {
        .room {
          &:first-child {
            margin-top: 50px;
          }
        }
      }
      .room {
        margin-top: 50px;
        .room_preview {
          flex-direction: column;
          .img-slider {
            width: 100%;
            .slick-dots {
              display: none!important;
            }
          }
          .room_info {
            position: relative;
            width: 100%;
            display: block;
            .room_box {
              position: static;
              transform: none;
              margin-top: -50px;
              margin-left: auto;
              margin-right: auto;
              height: auto;
              width: calc(100% - 80px);
              padding: 30px;
              justify-content: center;
              .price_nights_wrapper {
                display: block;
              }
            }
            .price_nights {
              display: none;
            }
            .button_row {
              display: inline-block;
              margin-top: 20px;
              margin-left: 15px;
            }
          }
        }
        .room_description {
          .description {
            margin-top: 30px;
          }
          .bottom_buttons {
            .button_row2 {
              flex-direction: column;
              margin-top: 35px;
              align-items: center;
              > a {
                width: 100%;
                max-width: 350px;
                margin-right: 0;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 550px) {
  .room_list {
    .bn_room_list {
      .room {
        .room_preview {
          .room_info {
            .room_box {
              width: calc(100% - 35px);
            }
          }
        }
        .room_description {
          .bottom_buttons {
            .button_row {
              a {
                width: 100%;
              }
              .button_book {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width: 450px) {
  .room_list {
    .bn_room_list {
      .room {
        .room_preview {
          .room_info {
            flex-direction: column;
            align-items: flex-start;
            .room_box {
              width: 100%;
              height: auto;
              position: relative;
              top: auto;
              left: auto;
              transform: none;
              padding: 15px;
            }
            .button_row {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}