.price_lists {
  .bn_price_lists {
    .room_prices {
      margin: 50px 0;
      .room_header {
        .room_name {
          font-family: $head_font;
          font-weight: bold;
          font-size: 4em;
          color: $brown;
          text-decoration: none;
          &:hover {
            color: $gold;
          }
        }
      }
      .price_list {
        @include price_table();
        margin-top: 0;
      }
      .bottom_buttons {
        margin-top: 25px;
        .button_row {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $head_font;
            min-width: 180px;
            height: 50px;
            border: 2px solid $gold;
            text-decoration: none;
            font-size: 2em;
            padding: 15px;
            margin-top: 10px;
          }
          .button_book {
            color: $white;
            background-color: $gold;
            margin-right: 20px;
            &:hover {
              color: $gold;
              background-color: $white;
            }
          }
          .button_request {
            color: $gold;
            background-color: $white;
            &:hover {
              color: $white;
              background-color: $gold;
            }
          }
        }
      }
    }
  }
}