.textplugin {
  font-size: 1.6em;
  font-family: $std_font;
  line-height: 26px;
  font-weight: $regular;
  h1, h2, h3, h4, h5, h6, ol, p, ul {
    margin: 15px 0;
  }
  img {
    width: 130px;
  }
  .bn-scrabble__table_wrap {
    table {
      tbody {
        tr {
          &:nth-child(2n + 1) {
            td {
              background-color: $light_grey;
            }
          }
        }
      }
    }
  }
}