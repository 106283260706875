/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.footer {
  position: relative;
  padding-bottom: 120px;
  padding-top: 100px;
  @include passiveLink();
  .upperfooter {
    display: flex;
    max-height: 300px;
    padding-top: 20px;
    border-top: 3px solid $brown;
    .footer_img {
      width: 16%;
      height: auto;
      margin: 0 10px;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%)translateY(-50%);
        height: auto;
        width: auto;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .hotel_info {
      width: 25%;
      padding-top: 20px;
      padding-left: 55px;
      height: auto;
      margin: 0 10px;
      background-color: $light_grey;
      .info_header {
        font-family: $head_font;
        color: $brown;
        font-size: 3em;
        position: relative;
        &:after {
          content: " ";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 55px;
          border-bottom: 1px solid $brown;
        }
      }
      .info {
        margin: 20px 0;
        > span {
          font-family: $std_font;
          font-size: 1.6em;
          display: block;
          line-height: 30px;
        }
      }
    }
    .footer_icons {
      width: 7.6%;
      height: auto;
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .icon_wrap {
        position: relative;
        width: 100%;
        height: calc(50% - 10px);
        background-color: $light_grey;
        &.oneIcon {
          height: 100%;
        }
        .icon {
          padding: 15px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%)translateY(-50%);
        }
      }
    }
  }
  .legal_wrapper {
    margin-top: 30px;
    .legalmenu {
      display: inline-block;
      a {
        font-size: 1.4em;
        color: $brown;
        &:hover {
          color: $gold;
        }
        &:after {
          content: " | ";
          margin-right: 3px;
        }
      }
    }
    .legalCopyright {
      display: inline-block;
      font-size: 1.4em;
      color: $brown;
    }
    .bn_link {
      display: inline-block;
      float: right;
      font-size: 1em;
      > a {
        display: block;
        width: 9em;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .mobile_bottom {
    display: none;
  }
  > .back_to_top {
    width: 65px;
    height: 65px;
    background-color: $gold;
    position: fixed;
    right: 20px;
    bottom: -65px;
    z-index: 2;
    cursor: pointer;
    transition: bottom .5s;
    &.docked {
      bottom: 100px;
    }
    &:hover {
      background-color: $brown;
    }
    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%)translateY(-50%);
      width: 100%;
      text-align: center;
      font-size: 50px;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */


@media only screen and (max-width: 1550px) {
  .footer {
    .upperfooter {
      .footer_img {
        width: 19%;
        &:last-child {
          display: none;
        }
        &:nth-child(5) {
          margin-right: 0;
        }
      }
      .hotel_info {
        width: 28%;
      }
      .footer_icons {
        width: 11%;
      }
    }
  }
}
@media only screen and (max-width: 1350px) {
  .footer {
    padding-top: 45px;
    > .back_to_top {
      right: 15px;
      &.docked {
        bottom: 95px;
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .footer {
    .upperfooter {
      .footer_img {
        width: 23.5%;
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(4) {
          margin-right: 0;
        }
      }
      .hotel_info {
        width: 35%;
      }
      .footer_icons {
        width: 18%;
      }
    }
  }
}

@media only screen and (max-width: 1099px) {
  .footer {
    padding-bottom: 65px;
    padding-top: 0;
    margin-top: 45px;
    > .back_to_top {
      width: 40px;
      height: 40px;
      right: 20px;
      &.docked {
        bottom: 20px;
      }
      .icon {
        font-size: 40px;
      }
    }
    .upperfooter {
      padding: 20px 20px 0;
      overflow: hidden;
      .footer_img {
        max-width: 40%;
        min-width: 260px;
        &:nth-child(4) {
          display: none;
        }
      }
      .hotel_info {
        min-width: 260px;
        width: 100%;
        padding-left: 35px;
        .info_header {
          font-size: 2em;
        }
        .info {
          > span {
            font-size: 1.4em;
            line-height: 24px;
          }
        }
      }
      .footer_icons {
        max-width: 29%;
        min-width: 165px;
        margin-right: 0;
      }
    }
    .legal_wrapper {
      margin: 15px 20px 0;
      text-align: center;
      .legalmenu {
        display: inline-block;
        a {
          font-size: 1.2em;
          color: $brown;
          &:hover {
            color: $gold;
          }
          &:after {
            content: " | ";
            margin-right: 3px;
          }
        }
      }
      .legalCopyright {
        display: inline-block;
        font-size: 1.2em;
        color: $brown;
      }
      .bn_link {
        display: block;
        float: none;
        margin-left: 45%;
        margin-top: 1.5em;
        font-size: 1em;
        > a {
          display: block;
          width: 8em;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    padding-bottom: 60px;
    > .back_to_top {
      display: none;
    }
    .upperfooter {
      flex-direction: column;
      padding: 15px 0 0;
      max-height: none;
      .footer_img {
        display: none;
      }
      .hotel_info {
        width: 100%;
        padding-left: 55px;
        min-height: 230px;
        margin: 0;
      }
      .footer_icons {
        max-width: 100%;
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
        padding: 0 15px;
        flex-direction: row;
        .icon_wrap {
          position: relative;
          width: calc(50% - 5px);
          height: 100%;
          background-color: $light_grey;
          display: flex;
          justify-content: center;
          align-items: center;
          &.oneIcon {
            height: 100%;
            width: 100%;
          }
          .icon {
            width: auto;
            max-height: 100px;
            height: 100%;
            top: 0;
            padding: 10px;
            transform: none;
            position: static;
          }
        }
      }
    }
    .legal_wrapper {
      margin: 15px 15px 0;
      .legalCopyright {
        .pipe {
          display: none;
        }
      }
    }
    .mobile_bottom {
      display: flex;
      position: fixed;
      bottom: -50px;
      transition: bottom .5s;
      left: 0;
      width: 100%;
      height: 50px;
      background-color: $white;
      z-index: 3005;
      &.docked {
        bottom: 0;
      }
      .center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%)translateY(-50%);
        width: 100%;
        text-align: center;
        font-size: 14px;
        &.icon {
          font-size: 50px;
        }
      }
      .mobile_bottom_link {
        width: calc(50% - 25px);
        height: 100%;
        background-color: $light_grey;
        display: inline-block;
        position: relative;
        margin-right: 3px;
        &:first-child {
          background-color: $light_brown;
        }
      }
      .back_to_top {
        width: 50px;
        height: 100%;
        background-color: $gold;
        position: relative;
        cursor: pointer;
      }
    }
  }
}