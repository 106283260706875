.headerbutton {
  position: absolute;
  bottom: 65px;
  left: 90px;
  width: 205px;
  height: 205px;
  background-color: transparent;
  border: 3px solid $brown;
  border-radius: 100%;
  .headerbutton_link, .headerbutton_nolink {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
    background-color: $gold;
    border: 1px solid $gold;
    border-radius: 100%;
    span {
      font-family: $head_font;
      font-size: 3.5em;
      line-height: 38px;
      text-align: center;
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%)translateY(-50%);
    }
  }
}
@media only screen and (max-width: 1350px) {
  .headerbutton {
    left: 60px;
  }
}

@media only screen and (min-width: 768px) and(max-width: 1099px) {
  .headerbutton {
    bottom: 55px;
    left: 15px;
    right: auto;
    width: 165px;
    height: 165px;
  }
}
@media only screen and (max-width: 767px) {
  .headerbutton {
    display: none;
  }
}