.treatments {
  @include center_text();
  .std_content_wrapper {
    position: relative;
    z-index: 1;
    .std_text {
      margin-bottom: 50px;
    }
  }
  .treatment_categories {
    margin-top: 50px;
    .categories {
      display: flex;
      flex-wrap: wrap;
      .categorie {
        position: relative;
        width: calc(50% - 10px);
        height: auto;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .bg_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          transition: all .5s;
          background-color: $brown_transparent_70;
        }
        .item {
          position: absolute;
          top: 20px;
          left: 40px;
          width: calc(100% - 80px);
          height: calc(100% - 40px);
          border: 2px solid $white;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          z-index: 1;
          span {
            color: $white;
            font-size: 3em;
            font-family: $head_font;
            padding: 0 10px;
            word-break: break-word;
            width: 100%;
          }
        }
        &:hover {
          .bg_overlay {
            background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
          }
          .item {
            border: none;
          }
        }
      }
    }
    .category_treatments {
      margin-top: 60px;
      .category_title {
        color: $brown;
        font-family: $head_font;
        font-weight: bold;
        font-size: 3em;
      }
      .treatment_item {
        margin-top: 30px;
        border-bottom: 2px solid $brown;
        .upper_item {
          display: flex;
          justify-content: space-between;
          .title {
            color: $brown;
            font-family: $head_font;
            font-size: 2.5em;
          }
          .price_duration {
            .duration {
              color: $brown;
              font-family: $head_font;
              font-size: 2em;
              .icon {
                margin-right: 10px;
              }
            }
            .price {
              color: $brown;
              font-family: $head_font;
              font-size: 2em;
              margin-left: 30px;
            }
          }
        }
        .sub_item {
          display: block;
          margin: 10px 0;
          font-size: 1.6em;
          color: $brown;
        }
      }
    }
  }
}

@media only screen and (max-width: 1099px) {
  .treatments {
    .treatment_categories {
      .categories {
        .categorie {
          width: calc(50% - 5px);
          margin-right: 10px;
          margin-bottom: 10px;
          .item {
            top: 10px;
            left: 20px;
            width: calc(100% - 40px);
            height: calc(100% - 20px);
            span {
              font-size: 2.5em;
            }
          }
        }
      }
      .category_treatments {
        margin-top: 50px;
        .category_title {
          font-size: 2.5em;
        }
        .treatment_item {
          margin-top: 25px;
          .upper_item {
            flex-wrap: wrap;
            .title {
              font-size: 2em;
            }
          }
          .sub_item {
            font-size: 1.4em;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .treatments {
    .treatment_categories {
      .categories {
        display: block;
        .categorie {
          width: 100%;
          max-width: 400px;
          margin: 10px auto;
          .item {
            span {
              font-size: 2.3em;
            }
          }
          &:nth-child(2n) {
            margin: 10px auto;
          }
        }
      }
      .category_treatments {
        .category_title {
          font-size: 2.3em;
        }
      }
    }
  }
}