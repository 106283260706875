/*  ==========================================================================
    VARIABLES
    Collection of all variables

    INFO:
    - try to use variables as much as possible, it makes life easier
    ========================================================================== */

@import 'lib/colors';

$std_font: 'Montserrat';
$head_font: "utopia-std-display",serif;

$regular: 400;