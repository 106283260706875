.slideboxes {
  .slidebox {
    border-top: 2px solid $brown;
    border-bottom: 2px solid $brown;
    margin: 20px 0;
    padding: 5px;
    transition: padding .4s;
    .trigger {
      position: relative;
      cursor: pointer;
      .title {
        font-size: 2.5em;
        font-family: $head_font;
        color: $brown;
        padding-right: 30px;
      }
      .icon {
        position: absolute;
        top: 0;
        right: 0;
        &:before {
          color: $brown;
        }
        &.close_box {
          font-size: 20px;
        }
        &.open_box {
          font-size: 40px;
        }
      }
    }
    .box_content {
      font-size: 1.6em;
      font-family: $std_font;
      color: $brown;
      margin-top: 20px;
      p {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .responsive-table table {
        text-align: left;
      }
    }

    &.close {
      .trigger {
        .icon.open_box {
          display: inline-block;
          right: -9px;
        }
        .icon.close_box {
          display: none;
        }
      }
    }

    &.open {
      padding: 10px 5px;
      .trigger {
        .icon.close_box {
          display: inline-block;
          top: 7px;
        }
        .icon.open_box {
          display: none;
        }
      }
    }

    &:hover {
      border-color: $gold;
      .trigger {
        .title {
          color: $gold;
        }
        .icon {
          &:before {
            color: $gold;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1099px) {
  .slideboxes {
    .slidebox {
      .trigger {
        .title {
          font-size: 2em;
        }
      }
      .box_content {
        font-size: 1.4em;
      }
      &.close {
        .trigger {
          .icon.open_box {
            top: -5px;
          }
        }
      }
      &.open {
        .trigger {
          .icon.close_box {
            top: 2px;
          }
        }
      }
    }
  }
}