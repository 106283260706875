.news_detail {
  .std_content_wrapper {
    position: relative;
    z-index: 1;
    .item {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 1099px) {
  .news_detail {
    .std_content_wrapper {
      .item {
        margin-top: 20px;
      }
    }
  }
}