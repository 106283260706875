/*  ==========================================================================
    MODULES
    Collection of all modules (except those which should be integrated 'inline')
    ========================================================================== */

// std gallery
@import 'lib/imagegallery';
@import 'lib/headerboxes';
@import 'lib/headerbutton';
@import 'lib/newsletter';
@import 'lib/partner';
@import 'lib/quickrequest';
@import 'lib/std_element';
@import 'lib/weather_detail';
@import 'lib/top_offers';
@import 'lib/google_maps';
@import 'lib/slideboxes';
@import 'lib/news_list';
@import 'lib/news_detail';
@import 'lib/treatments';
@import 'lib/offer_detail';
@import 'lib/request';
@import 'lib/roomList';
@import 'lib/offer_list';
@import 'lib/price_list';
@import 'lib/textplugin';
@import 'lib/html_element';
@import 'lib/download_boxes';