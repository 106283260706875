.news_list {
  position: relative;
  margin-top: 45px;
  padding-top: 25px;
  padding-bottom: 100px;
  .background_half {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $light_grey;
    z-index: 0;
  }
  @include center_text();
  .std_content_wrapper {
    position: relative;
    z-index: 1;
    .std_text {
      margin-bottom: 50px;
    }
  }
  .list_slider {
    position: relative;
    margin-top: 35px;
    .news_wrapper {
      .item {
        float: left;
        display: block;
        .item_wrapper {
          display: flex;
          .left {
            display: inline-block;
            width: 55%;
          }
          .right {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(42% - 20px);
            margin-left: 24px;
            padding: 100px 70px 30px 30px;
            .content {
              width: 100%;
              .contentTitle {
                color: $white;
                font-size: 4em;
                line-height: 45px;
                font-family: $head_font;
                font-weight: bold;
              }
              .description {
                margin-top: 20px;
                color: $white;
                font-size: 1.6em;
                line-height: 24px;
                font-family: $std_font;
              }
            }
            .button_row {
              position: absolute;
              left: 30px;
              bottom: 50px;
              .more_button {
                font-size: 2em;
                font-family: $head_font;
                height: 50px;
                min-width: 120px;
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $gold;
                color: $white;
                text-decoration: none;
                &:hover {
                  background-color: $light_brown;
                }
              }
            }
          }
        }
      }
      .icon.slick-arrow {
        position: absolute;
        right: 0;
        bottom: calc(86% / 2 + 40px);
        transform: translateY(50%);
        cursor: pointer;
        font-size: 65px;
        background-color: $light_brown;
        z-index: 2;
        &:hover {
          background-color: $brown;
        }
        &.next {
          bottom: calc(86% / 2 - 45px);
        }
      }
    }
    .wooden_bg {
      position: absolute;
      bottom: 0;
      right: 62px;
      width: calc(40% - 20px);
      height: 86%;
      background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
    }
    &.list_view {
      .wooden_bg {
        display: none;
      }
      .news_wrapper {
        .item {
          margin: 50px auto;
          float: inherit;
          .item_wrapper {
            .right {
              width: calc(45% - 20px);
              padding: 0 70px 30px 30px;
              margin-top: 80px;
              background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
            }
          }
          &:nth-child(2n) {
            .item_wrapper {
              flex-direction: row-reverse;
              .left {
                margin-left: 20px;
              }
              .right {
                margin-left: 0;
              }
            }
          }
          &:last-child {
            margin: auto;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1300px) {
  .news_list {
    .list_slider {
      .news_wrapper {
        .icon.slick-arrow {
          bottom: calc(86% / 2 + 20px);
          transform: translateX(50%)translateY(50%);
          font-size: 40px;
          &.next {
            bottom: calc(86% / 2 - 30px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1099px) {
  .news_list {
    .list_slider {
      .news_wrapper {
        .item {
          .item_wrapper {
            .right {
              width: calc(45% - 15px);
              margin-left: 15px;
              padding: 15px;
              .content {
                .contentTitle {
                  font-size: 3em;
                }
                .description {
                  font-size: 1.4em;
                }
              }
              .button_row {
                left: 15px;
                bottom: 15px;
                .more_button {
                  font-size: 1.6em;
                  height: 40px;
                  min-width: 100px;
                }
              }
            }
          }
        }
        .icon.slick-arrow {
          position: absolute;
          transform: none;
          &.next {
            right: 0;
            bottom: -50px;
          }
          &.prev {
            bottom: -50px;
            right: 50px;
          }
        }
      }
      .wooden_bg {
        right: 20px;
        width: calc(45% - 33px);
        height: 100%;
      }
      &.list_view {
        .news_wrapper {
          .item {
            .item_wrapper {
              .right {
                margin-top: 0;
                padding: 0 15px 15px 15px;
              }
            }
          }
        }
      }
    }
    &.list_view_wrapper {
      padding-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .news_list {
    .list_slider {
      .wooden_bg {
        display: none;
      }
      .news_wrapper {
        .item {
          .item_wrapper {
            display: block;
            .left {
              display: block;
              width: 100%;
            }
            .right {
              width: 100%;
              margin: 0;
              flex-direction: column;
              align-items: flex-start;
              background-image: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/wood.jpg);
              .content {
                margin-top: 10px;
              }
              .button_row {
                position: relative;
                left: auto;
                bottom: auto;
                margin-left: 0;
                margin-top: 20px;
              }
            }
          }
        }
        .icon.slick-arrow {
          position: absolute;
          transform: none;
          &.next {
            right: 0;
            bottom: -50px;
          }
          &.prev {
            bottom: -50px;
            right: 50px;
          }
        }
      }
      .wooden_bg {
        right: 20px;
        width: calc(45% - 33px);
        height: 100%;
      }
      &.list_view {
        .news_wrapper {
          .item {
            .item_wrapper {
              .right {
                width: 100%;
                margin-top: 0;
                padding: 0 15px 15px 15px;
              }
            }
            &:nth-child(2n) {
              .item_wrapper {
                flex-direction: column;
                .left {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}