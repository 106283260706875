@mixin center_text() {
  .std_content_wrapper {
    position: relative;
    z-index: 1;
    .headlines {
      h1,
      h2,
      .headline {
        text-align: center;
      }
      h3,
      .sub_headline {
        text-align: center;
      }
    }
    .std_text {
      text-align: center;
    }
  }
}