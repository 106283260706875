/*CUSTOM-COLOR*/
#dynform_quickrequest .bn__custom-color__background {
  background-color: $light_brown; /*color1*/
}
#dynform_quickrequest .bn__custom-color__background_button {
  background-color: $red; /*color2*/
  border: none;
  border-radius: 0!important;
  font-family: $head_font!important;
  &:hover {
    background-color: $gold; /*color2*/
  }
}
#dynform_quickrequest .bn__form-grid {
  color: $white; /*color3*/
}
#dynform_quickrequest input,
#dynform_quickrequest select {
  border-bottom: 1px solid $white; /*color3*/
}

.quickrequest_container {
  position: fixed;
  transition: bottom .5s;
  bottom: -81px;
  left: 0;
  width: 100%;
  background-color: $light_brown;
  display: flex;
  padding: 0 70px;
  z-index: 51;
  &.docked {
    bottom: 0;
  }
  .quickrequest_title {
    display: flex;
    align-items: center;
    span {
      font-family: $head_font;
      color: $white;
      font-size: 2.5em;
    }
  }
  #dynform_quickrequest {
    width: 100%;
    * {
      font-family: $std_font;
    }
    .bn__form-grid {
      margin: auto;
      display: flex;
      align-items: flex-end;
      .bn__form-grid__block.bn__form-grid__block--horizontal-1.first_half_row.phone {
        display: block;
      }
      .bn__form-grid__block {
        &.bn__form-grid__block--horizontal-5 {
          //width: 36.666667%;
        }
        &.bn__form-grid__block--horizontal-2 {
          //width: 14.666667%;
        }
        &.phone {
          label.bn__form-grid__column__item {
            //display: none;
          }
        }
        .bn__form-grid__row {
          .salutation label.bn__form-grid__column__item,
          .firstname label.bn__form-grid__column__item,
          .lastname label.bn__form-grid__column__item,
          .email label.bn__form-grid__column__item,
          .fromdate label.bn__form-grid__column__item,
          .todate label.bn__form-grid__column__item {
            //display: none;
          }
          .submit_form {
            //padding-top: 25px;
          }

          .bn__form-grid__column {
            .bn__form-grid__column__overlay {
              //top: 5px;
            }
            .bn__form-grid__column__item {
              padding: 0;
              &.required {
                &::placeholder {
                  font-weight: bold;
                }
              }
              &::placeholder {
                color: $white;
              }
            }
          }
        }
      }
    }
    .bn__form-grid.bn__form-grid--horizontal .bn__form-grid__block--horizontal-3 {
      margin-bottom: -10px;
      .consent_wrapper {
        width: 60%;
      }
      .submit_form {
        width: 40%;
      }
    }
  }
}
@media only screen and (max-width: 1350px) {
  .quickrequest_container {
    padding: 0 40px;
    .quickrequest_title {
      display: none;
    }
  }
}
@media only screen and (max-width: 1099px) {
  .quickrequest_container {
    display: none;
  }
}